import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <div className="introduction">
        <img className="logo" alt="Logo" src="logo.png" />
        <div className="copy">
          <h1>
            We design products that <span className="highlight">blend design and utility</span>. We believe digital products can have both style and substance. To that end, our focus is on story
            telling and the experience, as opposed to simply the interface.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default App;
